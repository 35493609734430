
  import AppButton from '@loavies/component-library/dist/app-button'

  export default {
    components: {
      AppButton,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    methods: {
      getButtonType(button) {
        return {
          [button.type]: true,
        }
      },
    },
  }
