
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        loading: true,
      }
    },
    computed: {
      ...mapState({
        isUserInitialized: state => state.user.isInitialized,
      }),
    },
    methods: {
      hideLoader() {
        this.loading = false
      },
    },
  }
