import { render, staticRenderFns } from "./modular-content.vue?vue&type=template&id=1e04d0fc"
import script from "./modular-content.vue?vue&type=script&lang=js"
export * from "./modular-content.vue?vue&type=script&lang=js"
import style0 from "./modular-content.vue?vue&type=style&index=0&id=1e04d0fc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spotlight: require('/opt/build/repo/components/spotlight/spotlight.vue').default,CategoryStories: require('/opt/build/repo/components/category-stories/category-stories.vue').default,ListingDealsCarousel: require('/opt/build/repo/components/listing-deals-carousel/listing-deals-carousel.vue').default,MostInDemandListingsCarousel: require('/opt/build/repo/components/most-in-demand-listings-carousel/most-in-demand-listings-carousel.vue').default,NewAdditionsListingsCarousel: require('/opt/build/repo/components/new-additions-listings-carousel/new-additions-listings-carousel.vue').default,ContentSkeletonLoader: require('/opt/build/repo/components/content-skeleton-loader/content-skeleton-loader.vue').default,InspirationalBanner: require('/opt/build/repo/components/dato/inspirational-banner/inspirational-banner.vue').default,ContentCardSection: require('/opt/build/repo/components/content-card-section/content-card-section.vue').default,ContentBanner: require('/opt/build/repo/components/content-banner/content-banner.vue').default,TextBlock: require('/opt/build/repo/components/common/text-block.vue').default,SeoTextBlock: require('/opt/build/repo/components/seo-text-block/seo-text-block.vue').default})
