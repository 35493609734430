
  import { mapActions } from 'vuex'

  import AppButton from '@loavies/component-library/dist/app-button'
  import AppAvatar from '@loavies/component-library/dist/app-avatar'
  import { UserModel } from '@loavies/c2c-models'

  import { SHOP } from '~/lib/constants/routes'
  import { FALLBACK_AVATAR_IMAGE } from '~/lib/constants/user'

  export default {
    components: {
      AppButton,
      AppAvatar,
    },
    props: {
      isReference: {
        type: Boolean,
        default: false,
      },
      user: {
        type: UserModel,
        required: true,
      },
      showBanner: {
        type: Boolean,
        default: false,
      },
      showListings: {
        type: Boolean,
        default: false,
      },
      banner: {
        type: String,
        default: '',
      },
    },
    emits: ['set-offset'],
    data() {
      return {
        followActionLoading: false,
        listings: [],
        listingsTotal: 0,
      }
    },
    computed: {
      userId() {
        return this.user.id
      },
      userShopLink() {
        return  this.localePath(`${SHOP}/${this.userId}`)
      },
      userAvatar() {
        return this.user.image?.variants.w200.url ?? FALLBACK_AVATAR_IMAGE
      },
      userShopName() {
        return this.user.displayName
      },
    },
    mounted() {
      this.fetchListings()

      if (this.isReference) {
        addEventListener('resize', () => this.calculatePaginationOffset())
        this.calculatePaginationOffset()
      }
    },
    beforeDestroy() {
      removeEventListener('resize', () => this.calculatePaginationOffset())
    },
    methods: {
      ...mapActions({
        showToastMessage: 'toast/showToastMessage',
      }),
      async fetchListings() {
        try {
          const { totals, listings } = await this.$elasticApiService
            .getListingsByUserId({
              userId: this.userId,
              page: 1,
              perPage: 20,
            })

          this.listingsTotal = totals.totalItems
          this.listings = listings

        } catch (error) {
          console.warn(this.$t('general_error'))
        }
      },
      calculatePaginationOffset() {
        if (!this.$refs.spotlightedUserBanner) return

        const bannerHeight = this.$refs.spotlightedUserBanner.getBoundingClientRect().height
        const shopInfoHeight = this.$refs.spotlightedUserShopInfo.getBoundingClientRect().height

        this.$emit('set-offset', `${bannerHeight + shopInfoHeight - 16}px`)
      },
    },
  }
