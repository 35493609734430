
  import { ListingSortOption } from '@loavies/c2c-models'

  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    emits: ['content-loaded'],
    data() {
      return {
        listings: null,
      }
    },
    computed: {
      allListingsCategory() {
        const categories = this.$staticDataService.globalNavigation?.navigation

        return categories?.find(category => category.showAllListings)
      },
      listingsToShow() {
        return this.listings ?? []
      },
    },
    async mounted() {
      this.listings = await this.$elasticApiService.getMostInDemandListings()

      this.$emit('content-loaded')
    },
    methods: {
      handleShowMore() {
        return this.$filterQueryHelperService.setSortOptionInQuery({
          path: this.localePath(this.allListingsCategory.link),
          sortOption: new ListingSortOption({
            key: 'publicData.wishlistCount',
            order: 'desc',
            translationKey: 'listing_sorting_most_likes',
          }),
        })
      },
    },
  }
