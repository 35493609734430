
  import { mapState } from 'vuex'

  import AppLabel from '@loavies/component-library/dist/app-label'
  import AppButton from '@loavies/component-library/dist/app-button'
  import VideoPlayer from '@loavies/component-library/dist/video-player'

  export default {
    components: {
      AppLabel,
      AppButton,
      VideoPlayer,
    },
    props: {
      banner: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        videoReady: false,
        videoError: false,
      }
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
      labelProps() {
        return {
          [this.banner.labelType]: this.banner.labelType !== 'dark',
          [this.banner.labelSize]: this.banner.labelSize !== 'default',
        }
      },
      mobileImage() {
        return `url(${this.banner.mobileImage?.url})`
      },
      desktopImage() {
        return `url(${this.banner.desktopImage?.url})`
      },
      video() {
        return this.isMobile ? this.banner.mobileVideo : this.banner.desktopVideo
      },
      videoSource() {
        return this.video?.url ?? null
      },
    },
    methods: {
      // The app-button expects props like 'secondary', this makes it possible to format
      // it in a way that the app-button expects the type prop since we use a v-bind="getButtonType()".
      // https://github.com/vuejs/vue/issues/4962#issuecomment-280779102
      getButtonType(button) {
        return {
          [button.type]: true,
        }
      },
    },
  }
