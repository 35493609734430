
  import AppButton from '@loavies/component-library/dist/app-button'

  export default {
    components: {
      AppButton,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      mobileImage() {
        return `url(${this.item.mobileImage?.url})`
      },
      desktopImage() {
        return `url(${this.item.desktopImage?.url})`
      },
    },
    methods: {
      // The app-button expects props like 'secondary', this makes it possible to format
      // it in a way that the app-button expects the type prop since we use a v-bind="getButtonType()".
      // https://github.com/vuejs/vue/issues/4962#issuecomment-280779102
      getButtonType(button) {
        return {
          [button.type]: true,
        }
      },
    },
  }
