
  import AppImage from '@loavies/component-library/dist/app-image'
  import VideoPlayer from '@loavies/component-library/dist/video-player'

  import { FAQ } from '~/lib/constants/routes'

  export default {
    components: {
      AppImage,
      VideoPlayer,
    },
    props: {
      cardDetails: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        videoError: false,
        videoReady: false,
      }
    },
    computed: {
      scaledImage() {
        return this.$responsiveImageService.getDatoImageResized({ url: this.cardDetails.image.url, width: 800 })
      },
      link() {
        return this.cardDetails.readMoreLink ? this.localePath(`${FAQ}/${this.cardDetails.readMoreLink.slug}`) : null
      },
      videoSource() {
        return this.cardDetails.video?.url ?? null
      },
    },
  }
