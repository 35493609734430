
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
    emits: ['content-loaded'],
    data() {
      return {
        listings: null,
      }
    },
    computed: {
      allListingsCategory() {
        const categories = this.$staticDataService.globalNavigation?.navigation

        return categories?.find(category => category.showAllListings)
      },
      listingsToShow() {
        return this.listings ?? []
      },
    },
    async mounted() {
      this.listings = await this.$elasticApiService.getNewAdditionListings()

      this.$emit('content-loaded')
    },
    methods: {
      handleShowMore() {
        return this.$router.push(this.localePath(this.allListingsCategory.link))
      },
    },
  }
