
  import TextAccordion from '@loavies/component-library/dist/text-accordion'

  export default {
    components: {
      TextAccordion,
    },
    props: {
      text: {
        type: String,
        required: true,
      },
    },
  }
