
  import AppSwiper from '@loavies/component-library/dist/app-swiper'

  export default {
    components: {
      AppSwiper,
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      listings: {
        type: Array,
        required: true,
      },
      showAuthor: {
        type: Boolean,
        default: false,
      },
      showMoreButton: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'show-more',
    ],
  }
