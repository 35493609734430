import { render, staticRenderFns } from "./listing-swiper.vue?vue&type=template&id=9a400678"
import script from "./listing-swiper.vue?vue&type=script&lang=js"
export * from "./listing-swiper.vue?vue&type=script&lang=js"
import style0 from "./listing-swiper.vue?vue&type=style&index=0&id=9a400678&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ListingCard: require('/opt/build/repo/components/listing-card/listing-card.vue').default})
