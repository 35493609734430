
  export default {
    props: {
      title: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
      narrowContainer: {
        type: Boolean,
        default: false,
      },
    },
  }
