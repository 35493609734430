
  export default {
    name: 'Homepage',
    async asyncData(context) {
      try {
        return await context.$datoApiService.getPageData(context.route)
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error while retrieving data:', error)
        }

        return context.error({ statusCode: 404, message: 'Not found.' })
      }
    },
    head() {
      return this.$pageHelperService.formatSeoForPage(this.page.seo, this.$route)
    },
    computed: {
      contentBlocks() {
        return this.page.contentBlocks.contentBlocks
      },
    },
  }
