
  import { mapState } from 'vuex'

  import HorizontalCarousel from '@loavies/component-library/dist/horizontal-carousel'
  import AppStory from '@loavies/component-library/dist/app-story'

  import { AGGREGATION_FILTER_KEYS } from '~/lib/services/api/elastic/elastic-api-queries'

  export default {
    components: {
      HorizontalCarousel,
      AppStory,
    },
    emits: ['content-loaded'],
    data() {
      return {
        productGroupOptions: [],
      }
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
      hideArrows() {
        return this.isMobile
      },
      categories() {
        return this.$staticDataService.globalNavigation?.navigation ?? []
      },
    },
    async mounted() {
      const response = await this.$elasticApiService.getListings({ perPage: 0 })

      this.productGroupOptions = response
        .filters
        .find(filter => filter.key === AGGREGATION_FILTER_KEYS.MODEL)
        .options

      this.$emit('content-loaded')
    },
    methods: {
      getProductCount(category) {
        if (!this.productGroupOptions.length) {
          return ''
        }

        let count = 0

        if (category.showAllListings) {
          this.productGroupOptions.forEach(option => {
            count += option.count
          })

          return this.$t('category_story_subtitle', { count })
        }

        let mergedProductGroups = [...category.productGroups]

        category.subcategories.forEach(subcategory => {
          mergedProductGroups.push(subcategory.productGroups)
        })

        mergedProductGroups = [...new Set(mergedProductGroups.flat())]

        mergedProductGroups.forEach(productGroup => {
          const productGroupOption = this.productGroupOptions.find(option => option.key === productGroup)

          count += productGroupOption ? productGroupOption.count : 0
        })

        return this.$t('category_story_subtitle', { count })
      },
    },
  }
