
  import { mapActions, mapGetters, mapState } from 'vuex'

  import AppButton from '@loavies/component-library/dist/app-button'

  export default {
    components: {
      AppButton,
    },
    props: {
      userId: {
        type: String,
        required: true,
      },
      squared: {
        type: Boolean,
        default: false,
      },
      noDefaultStyling: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        followActionLoading: false,
      }
    },
    computed: {
      ...mapState({
        currentUser: state => state.user.user,
      }),
      ...mapGetters({
        isFollowingUser: 'user/isFollowingUser',
        isAuthenticated: 'user/isAuthenticated',
      }),
      userIsFollowingShop() {
        return this.isFollowingUser(this.userId)
      },
      isOwnShop() {
        return this.userId === this.currentUser?.id
      },
      isFollowActionDisabled() {
        return !this.isAuthenticated || this.isOwnShop || this.followActionLoading
      },
    },
    methods: {
      ...mapActions({
        followUser: 'user/followUser',
        unfollowUser: 'user/unfollowUser',
      }),
      async handleUnfollowUser(userId) {
        this.followActionLoading = true
        await this.unfollowUser(userId)
        this.followActionLoading = false
      },
      async handleFollowUser(userId) {
        this.followActionLoading = true
        await this.followUser(userId)
        this.followActionLoading = false
      },
    },
  }
