
  export default {
    props: {
      content: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        skeletonItems: [
          'SpotlightRecord',
          'CategoryStoriesListRecord',
          'ListingDealsCarouselRecord',
          'MostInDemandListingsCarouselRecord',
          'NewAdditionsListingsCarouselRecord',
        ],
      }
    },
    methods: {
      isSkeletonItem(typeName) {
        return this.skeletonItems.includes(typeName)
      },
      skeletonSizeClass(item) {
        const cardCarouselRecords = [
          'ListingDealsCarouselRecord',
          'MostInDemandListingsCarouselRecord',
          'NewAdditionsListingsCarouselRecord',
        ]

        if (cardCarouselRecords.includes(item.__typename)) {
          return 'card-carousel-item'
        }

        if (item.__typename === 'SpotlightRecord' && item.showBanners) {
          if (item.spotlightedUsers.length === 1) {
            return 'spotlight-with-banner-and-listings'
          }

          return 'spotlight-with-banner'
        }

        if (item.__typename === 'SpotlightRecord' && !item.showBanners) {
          return 'spotlight'
        }

        if (item.__typename === 'CategoryStoriesListRecord') {
          return 'category-stories-list'
        }

        return item.__typename.toLowerCase()
      },
    },
  }
