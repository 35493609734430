
  import { mapState } from 'vuex'

  import AppSwiper from '@loavies/component-library/dist/app-swiper'

  export default {
    components: {
      AppSwiper,
    },
    props: {
      spotlightedUsers: {
        type: Array,
        required: true,
        validator: value => {
          if (!Array.isArray(value)) {
            return false
          }

          return value.every(childValue => {
            if (typeof childValue !== 'object') {
              return false
            }
            return (childValue.hasOwnProperty('userId') && childValue.hasOwnProperty('banner'))
          })
        },
      },
      title: {
        type: String,
        default: '',
      },
      showListings: {
        type: Boolean,
        default: false,
      },
      showBanner: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['content-loaded'],
    data() {
      return {
        spotlightedUserObjects: [],
        paginationOffset: 0,
      }
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
      shouldShowListings() {
        return this.showListings
          ? true
          : this.isMobile || this.spotlightedUserObjects.length < 2
      },
      shouldRenderUserSwiper() {
        return (this.spotlightedUserObjects.length > 3 && !this.isMobile)
          || (this.spotlightedUserObjects.length > 1 && this.isMobile)
      },
    },
    async mounted() {
      await this.fetchSpotlightedUsers()
      this.$emit('content-loaded')
    },
    methods: {
      async fetchSpotlightedUsers() {
        const newSpotlightUserObjects = []

        await Promise.all(
          this.spotlightedUsers.map(
            async ( { userId, banner }, index) => {
              try {
                const user = await this.$marketplaceUserService
                  .getUserById(userId)
                  .catch(error => {
                    if (error?.[0].statusCode === 404) {
                      return null
                    }

                    return Promise.reject(error)
                  })

                if (!user) {
                  return
                }

                newSpotlightUserObjects[index] = {
                  user,
                  banner,
                }
              } catch {
                console.warn(this.$t('something_went_wrong'))
              }
            })
        )

        this.spotlightedUserObjects = newSpotlightUserObjects.filter(Boolean)
      },
      setPaginationOffset(offset) {
        this.paginationOffset = offset
      },
    },
  }
