
  import { ListingPriceFilter } from '@loavies/c2c-models'

  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      maxPrice: {
        type: Number,
        required: true,
      },
    },
    emits: ['content-loaded'],
    data() {
      return {
        listings: null,
      }
    },
    computed: {
      allListingsCategory() {
        const categories = this.$staticDataService.globalNavigation?.navigation

        return categories?.find(category => category.showAllListings)
      },
      listingsToShow() {
        return this.listings ?? []
      },
    },
    async mounted() {
      this.listings = await this.$elasticApiService.getListingDeals(this.maxPrice)

      this.$emit('content-loaded')
    },
    methods: {
      handleShowMore() {
        return this.$filterQueryHelperService.setPriceFilterInQuery({
          path: this.localePath(this.allListingsCategory.link),
          priceFilter: new ListingPriceFilter({
            key: 'max_price',
            type: 'max',
            field: 'price.amount',
            amount: this.maxPrice * 100,
          }),
        })
      },
    },
  }
